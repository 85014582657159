import React, { useState, useEffect } from "react"
import { View, ScrollView, StyleSheet, TouchableOpacity, TextInput, Switch, Text, ActivityIndicator, Platform, Dimensions } from "react-native"
import { heightPercentageToDP as hp, widthPercentageToDP as wp } from "react-native-responsive-screen";
import CustomText from "../components/CustomText";
import { Wrap, Box } from 'react-native-flex-layout';
import CustomTextElipses from "../components/CustomTextElipses";
import Header from "../components/Header";
import { Feather } from '@expo/vector-icons';
import { fonts } from "../style/fonts";
import { Auth, API } from 'aws-amplify';
import Toast from 'react-native-root-toast';
import moment from "moment";
import DropDownPicker from 'react-native-dropdown-picker';

import Footer from "../components/Footer";

import AsyncStorage from '@react-native-async-storage/async-storage';

import Util from "../util/util.js"
import { useIsFocused } from "@react-navigation/native";
import webStyles from "../style/webStyles.js";
import mobileStyles from "../style/mobileStyles.js";


const { DateTime } = require("luxon");
const screenWidth = Dimensions.get('window').width

DropDownPicker.setMode("BADGE")

let scale = 1
// Adjust fonts for viewing the web portal on smaller devices
if (screenWidth < 800 && Platform.OS === "web") {
  scale = .7
}

const AdminFilterHome = (props) => {
    const { organization, admin } = props.route.params;

    const isFocused = useIsFocused();

    const [reports, setReports] = useState(null);
    const [routing, setRouting] = useState([]);

    const [displayedDate,setDisplayDate] = useState(moment());
    const [startDate,setStartDate] = useState(null);
    const [endDate,setEndDate] = useState(null);
    
    // Defining search string state
    const [searchText, setSearchText] = useState('');

    // For slider which determines whether they want already responded to reports
    const [includePreviousReports, setIncludePreviousReports] = useState(false);
    const toggleIncludePreviousReports = () => setIncludePreviousReports(previousState => !previousState);

    //For slider which determines whether they want the data sent as a csv to their email
    const [sendDataCSV, setSendDataCSV] = useState(false);
    const toggleSendDataCSV = () => setSendDataCSV(previousState => !previousState);

    const [displayFilter, setDisplayFilter] = useState(false);
    const toggleDisplayFilter = () => setDisplayFilter(previousState => !previousState);

    const defaultChecked = -1
    const[checked, setChecked] = useState(() => defaultChecked);

    const [reportsLoading, setReportsLoading] = useState(() => true)
    
    const [commentedReports, setCommentedReports] = useState(() => [])
    const [closedReports, setClosedReports] = useState(() => [])
    const [reopenedReports, setReopenedReports] = useState(() => [])

    // Since there's only a few HROs, and they aren't likely to change, hard-coding rather
    // than making extra API calls
    const [validHROs, setValidHROs] = useState(["PF", "RS", "SO", "CR", "DE"])
    const [HROFilterOpen, setHROFilterOpen] = useState(false);
    const [HROFilterValue, setHROFilterValue] = useState(null);
    const [HROFilterValueShouldReset, setHROFilterValueShouldReset] = useState(false);
    const [HROFilter, setHROFilter] = useState(validHROs.map(x => {return {'label': x, 'value': x}}));

    const [KPIFilterOpen, setKPIFilterOpen] = useState(false);
    const [KPIFilterValue, setKPIFilterValue] = useState([]);
    const [KPIFilterValueShouldReset, setKPIFilterValueShouldReset] = useState(false);
    const [KPIFilter, setKPIFilter] = useState([]);

    const [OrgFilterOpen, setOrgFilterOpen] = useState(false);
    const [OrgFilterValue, setOrgFilterValue] = useState([]);
    const [OrgFilterValueShouldReset, setOrgFilterValueShouldReset] = useState(false);
    const [validOrgs, setValidOrgs] = useState([])
    const [OrgFilter, setOrgFilter] = useState([organization]);
    const [loadingOrg, setLoadingOrg] = useState(true);
    
    
    
    //This function seems kind of unneccessary - unsure if check for containing key needs to happen
    const clearAsyncStorage = async (key) => {
      try{
        const allKeys = await AsyncStorage.getAllKeys();
        if(allKeys.includes(key)){
          await AsyncStorage.removeItem(key)
        }
      }catch(error){
        console.log("Error clearing async storage of", key)
        console.log(error)
      }
    }

    useEffect(() => {
      if(reports != null && reports.length > 0){
        //Code for updating reports display
      }
    }, [reports]);


    useEffect(() => {
      updateHROFilter()
    }, [HROFilterOpen]);

    useEffect(() => {
      updateKPIFilter()
    }, [KPIFilterOpen]);

    useEffect(() => {
      updateHROFilter()
    }, [HROFilterValue]);

    useEffect(() => {
      updateKPIFilter()
    }, [KPIFilterValue]);

    useEffect(() => {
      updateOrgFilter()
    }, [OrgFilterOpen]);

    useEffect(() => {
      updateOrgFilter()
    }, [OrgFilterValue]);

    useEffect(() => {
      if(isFocused){
        //update list of commented reports
        updateCache().then(() => {})
      }
    },[isFocused])

    useEffect(() => {
      if(checked != -1){
        generateReports(checked)
      }
    }, [checked, includePreviousReports])

    //Code to run on startup
    useEffect(() => {
      //On startup, set to default settings and generate reports.
      setChecked(1) //1 Is past 7 days
      updateValidOrgs()
    }, [])

    const updateValidOrgs = async () => {
      let organization_access = await AsyncStorage.getItem("organizationAccess")
      organization_access = organization_access.split(",")
      setValidOrgs(organization_access)
      setOrgFilter(organization_access.map(x => {return {'label': x, 'value': x}}))
    }


    useEffect(() => {
      setLoadingOrg(validOrgs.length != 0)
      if(OrgFilterValue === null || OrgFilterValue.length === 0)
      {
        setOrgFilterValue([organization])
      }
    }, [validOrgs]);


    //monitor commented reports for debugging
    useEffect(() => {    }, [commentedReports])

    const updateHROFilter = () => {
      if(!HROFilterOpen && (((HROFilterValue != null && HROFilterValue.length > 0)) || HROFilterValueShouldReset)){
        generateReports(checked)
      } 

      if(!HROFilterOpen) {
        setHROFilterValueShouldReset((HROFilterValue != null && HROFilterValue.length > 0) ? true : false)
      }
    }

    const updateKPIFilter = () => {
      if(!KPIFilterOpen && (((KPIFilterValue != null && KPIFilterValue.length > 0)) || KPIFilterValueShouldReset)){
        //Code for updating reports display
        generateReports(checked)
      } 

      if(!KPIFilterOpen) {
        setKPIFilterValueShouldReset((KPIFilterValue != null && KPIFilterValue.length > 0) ? true : false)
      }      
    }

    const updateOrgFilter = () => {
      if(!OrgFilterOpen && (((OrgFilterValue != null && OrgFilterValue.length > 0)) || OrgFilterValueShouldReset)){
        generateReports(checked)
      } 

      if(!OrgFilterOpen) {
        setOrgFilterValueShouldReset((OrgFilterValue != null && OrgFilterValue.length > 0) ? true : false)
      }
    }


    // Looks at cache and determines which reports should be displayed as commented or closed
    // Avoids having to fetch the entire list of reports unless needed.
    const updateCache = async () => {
      let cache = JSON.parse(await AsyncStorage.getItem("reportCommented"))
      let closedCache = JSON.parse(await AsyncStorage.getItem("reportClosed"))
      let reopenedCache = JSON.parse(await AsyncStorage.getItem("reportReopened"))
          
      let newList = []
      let closedList = []
      let reopenedList = []

      if(!reports || reports.length == 0){
        return(false)
      }
      //Go through every report
      reports.forEach(async (report, index) => {
        //Check both cache and contents of report
        const isOriginalCommented = !(report.comments === 'No comments yet' || report.comments.length == 0)
        const isCacheCommented = cache != null ? cache.includes(report.uuid) : false //Do not check cache if we know it's already reported
        if (closedCache != null && closedCache.includes(report.uuid)) {
          closedList.push(report.uuid)
        }
        if (reopenedCache != null && reopenedCache.includes(report.uuid)) {
          reopenedList.push(report.uuid)
        }

        if(isOriginalCommented || isCacheCommented){
          newList.push(report.uuid)
        }

      })
      setCommentedReports(newList)
      setClosedReports(closedList)
      setReopenedReports(reopenedList)
    }

    async function refresh() {

      if (searchText && searchText.trim()) {
        searchReports()
      } else {
        generateReports(checked)
      }

    }
    async function generateReports(checked){
      try {
        let routing = JSON.parse(await AsyncStorage.getItem("routing"))
        let routingData = Object.values(routing)
        
        setReportsLoading(true) 
        const user = await Auth.currentAuthenticatedUser()
        const token = user.signInUserSession.idToken.jwtToken
        var now = DateTime.now()
        var startTime = 0
        var _startDate = DateTime.fromMillis(0)
        var _endDate = DateTime.now()
        if(startDate && startDate != null && endDate && endDate != null){
          _startDate = DateTime.fromMillis(startDate.toDate().valueOf())
          _endDate = DateTime.fromMillis(endDate.toDate().valueOf())
        }

        switch(checked){
          case 0:
            startTime = now.minus({days:1})
            break
          case 1:
            startTime = now.minus({days:7})
            break
          case 2:
            startTime = now.minus({months:1})
            break
          case 3:
            startTime = DateTime.fromMillis(0)
            break
          case 4:
            //Use previously calculated startDate and endDate
            if(_endDate > _startDate){
              startTime = _startDate
              now = _endDate
            }else{
              startTime = _endDate
              now = _startDate
            }
            break
        }

        let organizations = organization;
        if (OrgFilterValue != null && OrgFilterValue.length > 0) {
          organizations = OrgFilterValue.toString()
        }
        const requestData = {
          headers: {
            Authorization: token
          },
          queryStringParameters: {
            startTime: startTime.toMillis(),
            endTime: now.toMillis(),
            organizations: organizations,
            organization: organization
          }
        }
        try{ 
          const data = await API.get('Resty','/items/queryTimeRange', requestData)
          const rawReports = data.items.sort((a, b) => b.timestamp - a.timestamp)
          
          if (KPIFilter.length == 0) {
            var validKPIs = []
            var validLocations = []

            rawReports.forEach(x => {
              for (var i in x.KPIs) {
                var selectedKPI = x.KPIs[i]
                if (!validKPIs.includes(selectedKPI)) {
                  validKPIs.push(selectedKPI)
                }
              }
              if (x.KPI_AI && !validKPIs.includes(x.KPI_AI)) {
                validKPIs.push(x.KPI_AI)
              }

              for (var i in x.locations) {
                var selectedLocation = x.locations[i]
                if (!validLocations.includes(selectedLocation)) {
                  validLocations.push(selectedLocation)
                }
              }
            })
  
            setKPIFilter([{'label': 'Hazard Classifications', 'value': 'Hazard Classifications'}])
            setKPIFilter((k) => k.concat(validKPIs.map(x => { return {'label': x, 'value': x, 'parent': 'Hazard Classifications'}})))

            setKPIFilter((l) => l.concat([{'label': 'Locations', 'value': 'Locations'}]))
            setKPIFilter((l) => l.concat(validLocations.map(x => { return {'label': x, 'value': x, 'parent': 'Locations'}})))
          }

          var filteredReports = rawReports

          if (HROFilterValue != null && HROFilterValue.length > 0) {
            filteredReports = filteredReports.filter(x => {
              for (var i in HROFilterValue) {
                var selectedHRO = HROFilterValue[i]
                if (x.HROs && x.HROs.includes(selectedHRO)) {
                  return x
                }
                if ((!x.HROs || !x.HROs.length > 0) && x.HRO_AI && x.HRO_AI.includes(selectedHRO)) {
                  return x
                }
              }
            })
          }

          if (KPIFilterValue != null && KPIFilterValue.length > 0) {
            let KPIFiltered = filteredReports
            let KPIValues = []
            KPIFiltered = filteredReports.filter(x => {
              for (var i in KPIFilterValue) {
                var selectedKPI = KPIFilterValue[i]
                if (x.KPIs && x.KPIs.includes(selectedKPI)) {
                  KPIValues.push(selectedKPI)
                  return x
                }
                if (x.KPI_AI && x.KPI_AI == selectedKPI) {
                  KPIValues.push(selectedKPI)
                  return x
                }
              }
            })
            if (KPIFiltered.length) {
              filteredReports = KPIFiltered
            }

            let locationValues = KPIFilterValue.filter(value => !KPIValues.includes(value))
        
            if (locationValues.length) {
              filteredReports = filteredReports.filter(x => {
                for (var i in locationValues) {
                  var selectedKPI = locationValues[i]
                  if (x.locations && x.locations.includes(selectedKPI)) {
                    return x
                  }
                }
              })
            }
          }

          var tempFilteredReports = []
          if(includePreviousReports){
            // Remove already responded to and has a certain topic
            for(var i = 0; i < filteredReports.length; i++){
              if(filteredReports[i].comments.length == 0 || filteredReports[i].comments[0] === 'No comments yet'){
                tempFilteredReports.push(filteredReports[i])
            }}
            filteredReports = tempFilteredReports
          } 

          //clear asyncstorage list of modified reports - there will be no benefit to using this after reloading.
          clearAsyncStorage("reportChanged")
          clearAsyncStorage("reportCommented")
          clearAsyncStorage("reportClosed")
          clearAsyncStorage("reportReopened")
          setClosedReports([])
          setReopenedReports([])

          setReports(filteredReports) // This is could perhaps lead to undefined behavior :/
          updateCache()
          setReportsLoading(false)
        }
        catch(error){
          Util.printAPIError(error)
        }
      } catch (e) {
        console.log("failed2: ",e.message);
      }
      setReportsLoading(false)
    };

    async function emailReportAsCsv(repsToEmail){
      try {
        const user = await Auth.currentAuthenticatedUser()
        const token = user.signInUserSession.idToken.jwtToken
        const requestData = {
          headers: {
            Authorization: token
          },
          body: {
            repsToEmail
          }
        }
        try{ 
          // Email the reports as a CSV.
          await API.post('Resty', '/items/emailCSV', requestData)
          Toast.show('✅ Successfully sent data as CSV to your email', {
            backgroundColor: "#259e23",
            duration: 5000,
            textColor: "#fff",
          });
        }
        catch(error){
          Util.printAPIError(error)
          alert("Temporarily unable to send CSV. Please try again.")
        }
      } catch (e) {
        console.log("emailReportAsCsv error: ",e.message);
      }
    };

    async function resetSearch() {
      setSearchText('')
      if(checked != -1){
        generateReports(checked)
      }
    }

    async function searchReports(){
      setReportsLoading(true)
      try { 
        const user = await Auth.currentAuthenticatedUser()
        const token = user.signInUserSession.idToken.jwtToken
        const requestData = {
          headers: {
            Authorization: token
          },
          queryStringParameters: {
            organization: organization,
            searchTerm: searchText
          }
        }

        try{ 
          const data = await API.get('Resty','/items/searchEmbeddings', requestData)
          setReports(data.items) // This is could perhaps lead to undefined behavior :/
        }
        catch(error){
          Util.printAPIError(error)
        }
      } catch (e) {
        console.log("failed: ",e.message);
      }
      setReportsLoading(false)
    };

    const setDates = (dates) => {
      setChecked(4)
      if(dates.endDate && dates.endDate != null){
        setStartDate(dates.endDate)//WHY???????????
      }
      else if(dates.startDate && dates.startDate != null){
        setEndDate(dates.startDate)//WHY IS IT REVERSED????
      }
    };

    const hasComments = (element) => {
      return (element.comments !== 'No comments yet' && element.comments.length > 0) || commentedReports.includes(element.uuid)
    }

    const isClosed = (element) => {
      if (reopenedReports.includes(element.uuid)) {
        return(false)
      }
      if (closedReports.includes(element.uuid)) {
        return(true)
      }
      return (element.isClosed) 
    }

    const timeOptions = [
      "Past 24 hours",
      "Past 7 Days",
      "Past Month",
      "All Time"
    ]
    return (
      <View style={Platform.OS === 'web' ? webStyles.container : mobileStyles.container}>
      <Header organization={organization} admin={admin} type='pop'/>
      <ScrollView style={{ flex: 1, backgroundColor: '#FFF' }}>
          {/* How long ago to generate reports */}
          <View style={{ alignItems: 'center', justifyContent: 'center'}}>
            {/* Chips for most frequently accessed options */}  
            <View style={styles.chip_container}>
              <Wrap shouldWrapChildren="true" spacing={6}>
                  {/* Chip Chips  */}
                  {
                      timeOptions.length == 0 ? null : timeOptions.map((element, index) =>{
                          return(
                              <TouchableOpacity key={index}  onPress={() => setChecked(index)}>
                                  <Box style = {checked == index ? styles.ChipSelected : styles.Chip}>
                                      <CustomText style = { styles.ChipText} font='medium' size={13} color={'#000'}>{screenWidth >= 800 ? element : element.replace('Past', '')}</CustomText>
                                  </Box>
                              </TouchableOpacity>
                          )
                      })
                  }
            <View style={{}}>
              <TouchableOpacity style={{}} onPress={refresh}>
                  <Feather name="refresh-cw" size={20 * scale} color={'#000'} />
                </TouchableOpacity>
            </View>
              </Wrap>
            </View>

            <View style={screenWidth >= 800 ? {position: 'absolute', right: wp(.5)} : {width: '100%', marginBottom: 5}}>
              <TouchableOpacity style={screenWidth >= 800 ? styles.emailCSVButton : styles.emailCSVButtonMobile} onPress={() => emailReportAsCsv(reports)}>
                <CustomText font='semiBold' color={'#000'}>Email CSV</CustomText>
              </TouchableOpacity>
            </View>
          </View>

          {/* Search Term */}
          <View style={{backgroundColor: '#FFF', alignItems: 'center', paddingHorizontal: wp(0.5)}}>
            <View style={styles.textboxContainer}> 
              <TouchableOpacity style={[styles.textboxIconContainer, displayFilter ? {borderTopRightRadius: 0, borderBottomRightRadius: 0, borderBottomLeftRadius: 0} : {borderTopRightRadius: 0, borderBottomRightRadius: 0} ]} onPress={toggleDisplayFilter}>
                <Feather name="filter" size={24 * scale} color={'#000'} />
              </TouchableOpacity>
              <TextInput value={searchText} onChangeText={(text) => { setSearchText(text) }} onSubmitEditing={searchReports} placeholder="Search Text" placeholderTextColor={'rgba(0,0,0,0.35)'} style={styles.textboxText} />
              <TouchableOpacity style={styles.textboxResetSearch} onPress={resetSearch}>
                <Feather name="x" size={24 * scale} color={'rgba(0,0,0,0.35)'} />
              </TouchableOpacity>
              <TouchableOpacity style={[styles.textboxIconContainer, displayFilter ? {borderTopLeftRadius: 0, borderBottomLeftRadius: 0, borderBottomRightRadius: 0} :{borderTopLeftRadius: 0, borderBottomLeftRadius: 0}]} onPress={searchReports}>
                <Feather name="search" size={24 * scale } color={'#000'} />
              </TouchableOpacity>
            </View>
          </View>

          {/* Filter Settings */}
          {displayFilter && <View style={styles.filterMenuContainerWeb}>
           <View style={[{width: '100%', flex: 1, marginBottom:'20px' }, screenWidth >= 800 ? {flexDirection: 'row', justifyContent: 'space-around'} : {}]}>

              <View style = {[styles.filterRow, screenWidth >= 800 ? {marginLeft: '5px', width: '40%', justifyContent: "flex-start"} : {marginLeft: 1, marginBottom: 5, width: '100%', justifyContent: "flex-start"}]}>
              <Switch
                      trackColor={{false: '#767577', true: '#FFC451'}}
                      thumbColor={includePreviousReports ? '#f5dd4b' : '#f4f3f4'}
                      onValueChange={toggleIncludePreviousReports}
                      value={includePreviousReports}
                      />   
              <Text style={[styles.ChipText, {marginVertical: 5}]}>Only include reports without any response</Text>
            </View>

            {OrgFilter !=null && OrgFilter.length > 1 && <View style={screenWidth >= 800 ? {marginLeft: '5px', width: '40%'} : {marginLeft: 1, marginBottom: 5, width: '100%'}}>
              <DropDownPicker
                  loading={loadingOrg}
                  zIndex={9}
                  multiple={true}
                  min={0}
                  open={OrgFilterOpen}
                  value={OrgFilterValue}
                  items={OrgFilter}
                  setOpen={setOrgFilterOpen}
                  setValue={setOrgFilterValue}
                  setItems={setOrgFilter}
                  dropDownDirection="TOP"
                  placeholder="Filter by Organization"
                  maxHeight={158}
              />
              </View>}
            </View>


              <View style={[{width: '100%', flex: 2 }, screenWidth >= 800 ? {flexDirection: 'row', justifyContent: 'space-around'} : {}]}>
              <View style={screenWidth >= 800 ? {marginLeft: '5px', width: '40%'} : {marginLeft: 1, marginBottom: 5, width: '100%'}}>
              <DropDownPicker
                  zIndex={9}
                  multiple={true}
                  min={0}
                  open={HROFilterOpen}
                  value={HROFilterValue}
                  items={HROFilter}
                  setOpen={setHROFilterOpen}
                  setValue={setHROFilterValue}
                  setItems={setHROFilter}
                  dropDownDirection="TOP"
                  placeholder="Filter by HRO"
                  maxHeight={158}
              />
              </View>

              <View style={screenWidth >= 800 ? {marginLeft: '5px', width: '40%'} : {marginLeft: 1, marginBottom: 5, width: '100%'}}>
              <DropDownPicker
                  zIndex={9}
                  multiple={true}
                  min={0}
                  open={KPIFilterOpen}
                  value={KPIFilterValue}
                  items={KPIFilter}
                  setOpen={setKPIFilterOpen}
                  setValue={setKPIFilterValue}
                  setItems={setKPIFilter}
                  dropDownDirection="TOP"
                  searchable={true}
                  categorySelectable={false}
                  placeholder="Filter by Hazard Classification"
                  maxHeight={158}
                  listParentLabelStyle={{fontWeight: "bold"}}
              />
              </View>
            </View>
          </View>}

          <View style={[styles.orangeContainer, {zIndex: -1}]}>
            {/* Orange separators  */}

            {/* Report mapping */}
            <ScrollView style={{ paddingTop: hp(1), width: '100%' }}>
              {(reports == null || reportsLoading) && <ActivityIndicator size="large"/>}
              {
                // We assume that whatever reports that need to be seen were passed in to this page as a parameter, so we do not filter them here
                reports != null && reports.map((element, index) => {
                  return (
                    <TouchableOpacity key={index} onPress={() => { element.index = reports.length-index; props.navigation.navigate('AdminReportDetails', element) }}>
                      <View key={index} style={webStyles.reportContainer}>
                        <View style={[webStyles.reportCheck, Platform.OS !== 'web' ? {height: hp(10)} : {}]}>
                          {(element.isUrgent && !hasComments(element) && !isClosed(element)) &&
                            <Feather name={"alert-circle"} size={20 * scale} color={'red'} />
                          }  
                          {(hasComments(element) && !isClosed(element)) &&
                            <Feather name={"inbox"} size={20 * scale} color={'rgba(255, 196, 81, 1)'} />
                          }         
                          {(isClosed(element)) &&
                            <Feather name={"check"} size={20 * scale} color={'green'} />
                          }   

                        </View>   
                        <View style={webStyles.reportInfoContainer}>
                          <View  style={webStyles.reportInfo}>
                            <CustomText style={webStyles.reportInfoItem} font='light' size={14}>Report ID: {Util.formatUuid(element.uuid)}</CustomText> 
                            <CustomText style={webStyles.reportInfoItem} font='light' size={14}>Sent: {Util.timeConverter(element.timestamp)}</CustomText>
                          </View> 
                          <CustomTextElipses numberOfLines={1} ellipsizeMode="tail" style={webStyles.report} size={24 * scale}>{Util.formatDescription(element.content)}</CustomTextElipses>   
                        </View>
                      </View>
                    </TouchableOpacity>
                  )
                })
              }
              {(!reportsLoading && reports.length == 0) && 
              <CustomText font='medium' size={24} style={[webStyles.report, {textAlign: 'center'}]}>
                No reports submitted
              </CustomText>}
            </ScrollView>

          </View>
          </ScrollView>
        <Footer admin={admin} />
      </View>
    )   
}

const styles = StyleSheet.create({
  filterMenuContainer: {
    flex: 1,
    alignItems: 'center',
    backgroundColor: 'rgba(200, 200, 200, 0.3)',
    paddingVertical: 10,
    paddingHorizontal: 2,
    borderBottomLeftRadius: 12,
    borderBottomRightRadius: 12,
    marginHorizontal: 2,
  },
  filterMenuContainerWeb: {
    alignItems: 'center',
    backgroundColor: 'rgba(200, 200, 200, 0.3)',
    paddingVertical: 10,
    paddingHorizontal: 2,
    borderBottomLeftRadius: 12,
    borderBottomRightRadius: 12,
    marginHorizontal: wp(0.5),
  },
  filterRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    //width: '100%',
    flexWrap: 'wrap'
  },
  orangeContainer: {

    alignItems: 'center',
    borderRadius: 12,
    backgroundColor: 'rgba(255, 196, 81, 0.3)',
    paddingVertical: 10,
    paddingHorizontal: 14,
    marginTop: hp(1)
  },
  textboxContainer: {
    flexDirection: 'row',
    backgroundColor: 'rgba(255, 196, 81, 0.3)',
    borderRadius: 5,
    width: '100%',
    height: hp(6.8),
    marginTop: hp(0),
    justifyContent: 'center',
  },
  textboxIconContainer: {
    flex: 1,
    backgroundColor: '#FFC451',
    borderRadius: 5,
    justifyContent: 'center',
    alignItems: 'center',
  },
  textboxResetSearch: {
    flex: 1,
    backgroundColor: 'rgba(255, 196, 81, 0.3)',
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: 50
  },
  textboxIconContainerEye: {
    backgroundColor: '#FFC451',
    borderRadius: 12,
    width: '9%',
    justifyContent: 'center',
    alignItems: 'center'
  },
  textboxText: {
    flex: 4,
    fontFamily: fonts.POPPINS.regular,
    fontSize: 24 * scale,
    color: '#000',
    marginLeft: 4,
    marginBottom: -4,
    width: '100%'
  },
  button: {
    cursor: 'pointer',
    backgroundColor: '#FFC451',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 12,
    height: hp(6.8),
    width: '70%',
    alignSelf: 'center',
    marginTop: hp(2.5)
  },
  emailCSVButton: {
    cursor: 'pointer',
    backgroundColor: '#FFC451',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 12,
    alignSelf: 'center',
    height: hp(3.4),
    width: 100
  },
  emailCSVButtonMobile: {
    cursor: 'pointer',
    backgroundColor: '#FFC451',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 12,
    alignSelf: 'left',
    height: hp(4),
    width: 100,
    marginBottom: 1,
    marginTop: 5,
    marginLeft: 2
  },
  rbUtton: {
    flex: 1, size: 5
  },
  container: {
    backgroundColor: "white",
    flex: 1,
  },
  Chip:{
    marginHorizontal: wp(0),
    backgroundColor: 'rgba(200,200,200,0.3)',
    justifyContent: 'center',
    flexDirection: 'row',
    flex: 1,
    borderRadius: 100,
    borderColor: 'rgb(0,0,0)',
    borderWidth: 1,
  },
  ChipSelected:{
    marginHorizontal: wp(0),
    backgroundColor: '#FFC451',
    justifyContent: 'center',
    flexDirection: 'row',
    flex: 1,
    borderRadius: 100,
    borderColor: 'rgb(0,0,0)',
    borderWidth: 1,
  },
  ChipAdd:{
    marginHorizontal: wp(0),
    borderRadius: 100,
    backgroundColor: '#CCCCCC',
    justifyContent: 'center',
    flex: 1,
    borderColor: 'rgb(0,0,0)',
    borderWidth: 1,
    minHeight: hp(3),
  },
  ChipText:{
    marginHorizontal: wp(2 * scale),
  },
  ChipDeleteText:{
    marginRight: wp(1),
    borderRadius: 100,
  },
  chip_container: {
    backgroundColor: "white",
    flex: 1,
    paddingVertical: hp(1),
    paddingHorizontal: wp(1),
  },
  DateRangePicker:{
    marginHorizontal: wp(1),
    marginVertical: hp(1),
    backgroundColor: 'rgba(200,200,200,0.3)',
    justifyContent: 'center',
    flexDirection: 'row',
    borderRadius: 100,
    borderColor: 'rgb(0,0,0)',
    borderWidth: 1,
  },
  DateRangePickerSelected:{
    marginHorizontal: wp(1),
    marginVertical: hp(1),
    backgroundColor: '#FFC451',
    justifyContent: 'center',
    flexDirection: 'row',
    borderRadius: 100,
    borderColor: 'rgb(0,0,0)',
    borderWidth: 1,
  },
})

export default AdminFilterHome;