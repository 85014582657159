import React, { useState, useEffect } from "react"
import { KeyboardAvoidingView, Keyboard, View, StyleSheet, Image, Dimensions, TouchableWithoutFeedback, TextInput, ScrollView, Platform, ActivityIndicator } from "react-native"
import { heightPercentageToDP as hp, widthPercentageToDP as wp } from "react-native-responsive-screen";
import CustomText from "../components/CustomText";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Separator from "../components/Separator";
import Checkbox from "../components/Checkbox";
import { fonts } from "../style/fonts";
import { Auth, API, Amplify } from 'aws-amplify';
import { KeyboardAccessoryNavigation } from 'react-native-keyboard-accessory'
import Toast from 'react-native-root-toast';
import { useActionSheet } from '@expo/react-native-action-sheet';
import DropDownPicker from 'react-native-dropdown-picker';

import AsyncStorage from '@react-native-async-storage/async-storage';

import MonetaImageGallery from "../components/MonetaImageGallery";


import ChipSection from "../components/ChipSection";
import { TouchableOpacity } from "react-native-gesture-handler";
import Util from "../util/util.js"

import * as FileSystem from 'expo-file-system';
import { Feather } from "@expo/vector-icons";
import webStyles from "../style/webStyles.js";
import mobileStyles from "../style/mobileStyles.js";
import {Chip_Styles, Web_Chip_Styles} from "../style/ChipStyles.js"

const docDir = FileSystem.documentDirectory;
const screenWidth = Dimensions.get('window').width

let scale = 1
// Adjust fonts for viewing the web portal on smaller devices
if (screenWidth < 800 && Platform.OS === "web") {
  scale = .6
}

Amplify.configure({

})

const SubmitReport = (props) => {
    const { showActionSheetWithOptions } = useActionSheet();
    const { organization, admin } = props.route.params;

    // Defining local state value
    const [anonymous, setAnonymous] = useState(() => true)
    const [isTest, setIsTest] = useState(() => false)
    const [isUrgent, setIsUrgent] = useState(() => false)
    const [textBoxInput2, setTextBoxInput2] = useState('');
    
    const [routing, setRouting] = useState([])

    const [KPIS, setKPIS] = useState([])
    const [validKPIs, setValidKPIs] = useState([""])

    const [Locations, setLocations] = useState([])
    const [validLocations, setValidLocations] = useState([""])

    const [imagesToSend, setImagesToSend] = useState([]);
    const [uploadedImages, setUploadedImages] = useState([])//Required for upload successful images button

    const [uploadProgress, setUploadProgress] = useState({})

    const [uploadStage, setUploadStage] = useState("Picking");

    const [imageLoading, setImageLoading] = useState(false)   

    const [errors, setErrors] = useState({}); 
    const [isFormValid, setIsFormValid] = useState(false); 
    const [displayErrors, setDisplayErrors] = useState(false); 

    const [isSubmitting, setIsSubmitting] = useState(false); 

    const [OrgFilterOpen, setOrgFilterOpen] = useState(false);
    const [OrgFilterValue, setOrgFilterValue] = useState(organization);
    const [validOrgs, setValidOrgs] = useState([])
    const [OrgFilter, setOrgFilter] = useState(validOrgs.map(x => {return {'label': x, 'value': x}}));

    //Upon startup
    useEffect(() => {
      
      const getRouting = async () => {
        let routingData = JSON.parse(await AsyncStorage.getItem("routing"))
        setRouting(routingData)
      }

      getRouting()
      //Update Valid KPIs so user can select from them
      getValidChips("KPI").then(e => {
        const textOnly = e.map((value, index) => {
          return value["value"] //Only keep the content of the KPI and not metadata
        })
        setValidKPIs(() => textOnly)
      })

      getValidChips("Location").then(e => {
        const textOnly = e.map((value, index) => {
          return value["value"] //Only keep the content of the KPI and not metadata
        })
        setValidLocations(() => textOnly)
      })     
      updateValidOrgs()
    }, [])

    const updateValidOrgs = async () => {
      let organization_access = await AsyncStorage.getItem("organizationAccess")
      organization_access = organization_access.split(",")
      setValidOrgs(organization_access)
      setOrgFilter(organization_access.map(x => {return {'label': x, 'value': x}}))
    }

        useEffect(() => {
          setKPIS([])
          setLocations([])
      //Update Valid KPIs so user can select from them
      getValidChips("KPI").then(e => {
        const textOnly = e.map((value, index) => {
          return value["value"] //Only keep the content of the KPI and not metadata
        })
        setValidKPIs(() => textOnly)
      })

      getValidChips("Location").then(e => {
        const textOnly = e.map((value, index) => {
          return value["value"] //Only keep the content of the KPI and not metadata
        })
        setValidLocations(() => textOnly)
      })  
        }, [OrgFilterValue]);

    //Update uploadProg dict when image is picked or dropped - this should never happen during upload time
    useEffect(() => {
      var uploadProg = {};
      if (!imagesToSend.length) {
        return
      }

      imagesToSend.forEach((value, index) => {
        uploadProg[value] = "Picked";
      })
      setUploadProgress(() => uploadProg);
      console.log("upload progress: ",uploadProg);
    }, [imagesToSend])

    useEffect(() => {
      console.log("uploadedImages updated: ",uploadedImages)
    }, [uploadedImages])

    useEffect(() => { 
      // Require description
      validateForm(); 
  }, [textBoxInput2]); 

    const validateForm = () => { 
      let errors = {}; 

      // Require description
      if (!textBoxInput2.trim()) { 
          errors.description = 'Description is required.'; 
      } 

      // Set the errors and update form validity 
      setErrors(errors); 
      setIsFormValid(Object.keys(errors).length === 0); 
  }; 

    const getValidChips = async (chip_type) => {
      const user = await Auth.currentAuthenticatedUser()
      const token = user.signInUserSession.idToken.jwtToken

      const requestData = {
        headers: {
          Authorization: token
        },
        queryStringParameters: {
          organization: OrgFilterValue ? OrgFilterValue : organization,
          value_type: chip_type
        }
      }
      try{ 
        const data = await API.get('Resty','/items/organization_configuration/value_type', requestData)
        //sort chips by value, but return including metadata
        return(data.items.sort((a,b) => {
          const upperA = a.value.toUpperCase()
          const upperB = b.value.toUpperCase()

          if (upperA < upperB) {
            return -1;
          }
          if (upperA > upperB) {
            return 1;
          }
          return 0;
        }))
      }
      catch(error){
        Util.printAPIError(error)
      }
      return([])
    }

    function generateUUID() { // Public Domain/MIT
      var d = new Date().getTime();//Timestamp
      var d2 = ((typeof performance !== 'undefined') && performance.now && (performance.now()*1000)) || 0;//Time in microseconds since page-load or 0 if unsupported
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
          var r = Math.random() * 16;//random number between 0 and 16
          if(d > 0){//Use timestamp until depleted
              r = (d + r)%16 | 0;
              d = Math.floor(d/16);
          } else {//Use microseconds since page-load if supported
              r = (d2 + r)%16 | 0;
              d2 = Math.floor(d2/16);
          }
          return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
      });
    }

    const postNewTableEntry = async (tryUploadImage = false) => {
      if (isSubmitting) { return } // Don't submit duplicates!

      // Validate form before proceeding. This will prevent invalid reports from being submitted.
      setDisplayErrors(true);
      if (!isFormValid) { return; } 

      setIsSubmitting(true)
      try {
        const user = await Auth.currentAuthenticatedUser()
        const token = user.signInUserSession.idToken.jwtToken

        let [fullImageURLs, fulfilledImageURLs] = await Util.getUploadedImageURLs(tryUploadImage, uploadedImages, imagesToSend, setUploadedImages, setUploadStage, setUploadProgress)

        let reportId = generateUUID()
        let userEmail = user.attributes.email

        const allTags = [...KPIS, ...Locations]

        const routeKey = Object.keys(routing).find(key => allTags.includes(key))
        const routedOrg = routeKey ? routing[routeKey] : null

        const shortReportId = Util.formatUuid(reportId)
        let details = "<b>Sent:</b> " + Util.timeConverter(Date.now()) + "<br/>" +
        "<b>Report ID:</b> " + shortReportId + "<br/>" + 
        "<b>Description</b>: " + textBoxInput2 + "<br />" + 
        "<b>Hazard Classifications: </b>" + KPIS.toString() + "<br />" + 
        "<b>Locations: </b>" + Locations.toString(); 

        if (!anonymous) {
          details += "<br/> <b>Reporter:</b> " + userEmail
        }

        if (fullImageURLs.length > 0) {
          details += "<br/><br/><b>Images:</b><br/>"
          for (const img in fullImageURLs) {
            details += `<img src="cid:unique@screenshot-${img}" style="width: 100px"/><br/>`      
          }         
        }

        let message = "A new near-miss report was submitted.<br/><br/>"

        API.post('Resty', '/items', {
          headers: {
            Authorization: token
          },
          body: {
            user: userEmail,
            timestamp: Date.now(),
            comments: [], //Stop using the No Comments Yet convention. Just use an empty list
            firstCommentDate: null,
            commentWaitTime: null,
            content: textBoxInput2,
            organization: routedOrg ? routedOrg : OrgFilterValue ? OrgFilterValue : organization,
            uuid: reportId,
            reporter: anonymous == true ? "Anonymous" : userEmail,
            isTest: isTest,
            isUrgent: isUrgent,
            KPIs: KPIS,
            HROs: [],
            locations: Locations,
            images: fulfilledImageURLs,
            shortReportId: shortReportId,
            message: message,
            details: details,
            fullImageURLs: fullImageURLs
          }
        }).then(result => {
          console.log(JSON.stringify(result, null, 2));
          Toast.show('✅ Report sent', {
            backgroundColor: "#259e23",
            position: Toast.positions.BOTTOM,
            duration: 2500,
            textColor: "#fff",
        });
          setIsSubmitting(false)
          props.navigation.pop() 
        }).catch(error => {
          Util.printAPIError(error)
          setIsSubmitting(false)
        })
      } catch (e) {
        console.log(e.message);
        setIsSubmitting(false)
      }
    };

    const selectPhotoOptions = async () => {

      if (Platform.OS === 'web') {
        Util.pickImage(Util.imageMode.gallery, setImageLoading, setImagesToSend)
        return;
      }
      const options = ['Photo Gallery', 'Camera', 'Cancel'];

      showActionSheetWithOptions({options,}, (selectedIndex) => {
        Util.pickImage(selectedIndex, setImageLoading, setImagesToSend)
      });
    }
  
    return (
      <View style={[Platform.OS === 'web' && screenWidth >= 800 ? webStyles.container : mobileStyles.container, styles.container]}>
      <Header organization={organization} admin={false} type='pop' />
  
      {uploadStage && (uploadStage == "Uploading" || uploadStage == "Finished" || uploadStage == "Failed") && 
       uploadProgress && Object.keys(uploadProgress).length > 0 &&
        <ScrollView containerStyle = {{alignItems: 'center',
        justifyContent: 'center',
        position: 'absolute',
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
        zIndex: 1
        }}>
          {
            uploadProgress && Object.keys(uploadProgress).length > 0 && 
            <View style={{borderRadius: 12, borderColor: '#000', borderWidth: 1, height: hp(80), margin: wp(5), backgroundColor: "#ffffff", alignItems: 'center', flexDirection: 'column'}}>
              { Platform.OS == "web" && screenWidth >= 800 ?
                  <View style={{flex: 0, paddingBottom: hp(10)}}>
                    <CustomText style={{}} font='medium' size={25} color={'#000'}>Upload Progress</CustomText>
                  </View> :

                  <View style={{flex: 0}}>
                    <CustomText style={{}} font='medium' size={25} color={'#000'}>Upload Progress</CustomText>
                  </View>
                }
                            
              {Object.keys(uploadProgress).map((key, index) => {
                const status = uploadProgress[key]
                var indicator = ""
                console.log(status)
                console.log("key: ",key)
                switch(status){
                  case "Picked":
                    indicator = <Feather name='arrow-up-circle' size={24} color={'#000'} style={{}} />
                    break
                  case "Uploading":
                    indicator = <ActivityIndicator size="large"/>
                    break
                  case "Failed":
                    indicator = <Feather name='alert-circle' size={24} color={'#000'} style={{}} />
                    break
                  case "Finished":
                    indicator = <Feather name='check-circle' size={24} color={'#000'} style={{}} />
                    break
                }

                if(Platform.OS=="web" && screenWidth >= 800){
                  return(
                    <View style={{flexDirection: 'row'}}>
                      <Image style={{height: wp(7), width: wp(7)}} source={{uri: key}}/>
                      <CustomText style={{ marginLeft: wp(5), paddingHorizontal: wp(5), flex: 5 }} font='medium' size={20} color={'#000'}>Image {index + 1}: {uploadProgress[key]}</CustomText>
                      <View style={{ marginLeft: wp(5), paddingHorizontal: wp(5), flex: 1 }}>
                        {indicator}
                      </View>
                    </View>
                  );
                }else{
                  return(
                    <View style={{flexDirection: 'row'}}>
                      <Image style={{height: wp(7), width: wp(7)}} source={{uri: key}}/>
                      <CustomText style={{ marginLeft: wp(3), flex: 5 }} font='medium' size={20} color={'#000'}>Image {index + 1}: {uploadProgress[key]}</CustomText>
                      <View style={{ marginLeft: wp(3), flex: 1 }}>
                        {indicator}
                      </View>
                    </View>
                  );
                }
              })}
              {(uploadStage == "Failed" || Util.anyUploadsFailed(uploadProgress)) && <View>
                <TouchableOpacity onPress={() => {setUploadStage("Picking")}} style={{ cursor: 'pointer', justifyContent: 'center', alignItems: 'center', backgroundColor: '#FFC451', borderRadius: 12, marginVertical: hp(1)}}>
                  <CustomText style={styles.buttonText} font='medium' size={20} color={'#000'}>Cancel</CustomText>
                </TouchableOpacity>
                <TouchableOpacity onPress={() => {postNewTableEntry(true)}} style={{ cursor: 'pointer', justifyContent: 'center', alignItems: 'center', backgroundColor: '#FFC451', borderRadius: 12, marginVertical: hp(1)}}>
                  <CustomText style={styles.buttonText} font='medium' size={20} color={'#000'}>Retry</CustomText>
                </TouchableOpacity>
                <TouchableOpacity onPress={() => {postNewTableEntry(false)}} style={{ cursor: 'pointer', justifyContent: 'center', alignItems: 'center', backgroundColor: '#FFC451', borderRadius: 12, marginVertical: hp(1)}}>
                  <CustomText style={styles.buttonText} font='medium' size={20} color={'#000'}>Submit with successful uploads</CustomText>
                </TouchableOpacity>
              </View>}
            </View>
          }
      </ScrollView>
      }
      
      <ScrollView style={styles.baseContainer}>
        {/* Global header  */}
        

        {/* So pressing anywhere on the screen will dismiss the keyboard while typing in a report 
        <TouchableWithoutFeedback onPress={Keyboard.dismiss} accessible={false} >*/}
        <TouchableWithoutFeedback onPress={Platform.OS != 'web' && screenWidth >= 800 ? Keyboard.dismiss : console.log("")} accessible={false} >
        <View style={{ flex: 1, paddingHorizontal: wp(6), paddingTop: hp(1.7)}}>
          {/* Title  */}
          <CustomText style={{ textAlign: 'center' }} font='medium' size={32} color={'#000'}>Submit a report</CustomText>


          <Separator />


          
          {/* Description textbox  */}
          <KeyboardAvoidingView style={styles.orangeContainer}>
            {textBoxInput2 === '' && <CustomText style={styles.placeholder}>Description</CustomText>}
            <TextInput allowFontScaling={false} editable multiline numberOfLines={8} onChangeText={(textBoxInput2) => setTextBoxInput2(textBoxInput2)} placeholder="" style={styles.textInput} />
          </KeyboardAvoidingView>

          <CustomText style={styles.error}>{displayErrors ? errors.description: null}</CustomText>

          {/* Select Image to upload */}
          <TouchableOpacity onPress={selectPhotoOptions} style={styles.imageUploadButton}>
            <CustomText style={styles.buttonText} size={Platform.OS === 'web' || screenWidth >= 800 ? 32 : 24} font='medium'  color={'#000'} width={'100%'}>Select Images to Upload {(imageLoading) &&  <ActivityIndicator  size="small"/>}</CustomText>
          </TouchableOpacity>

          <MonetaImageGallery
            images = {imagesToSend}
            setImages = {setImagesToSend}
            allowDeletion = {true}
          />

          
          {/* Anonymous report checkbox  */}
          <View style={{ flexDirection: 'row', alignItems: 'center', paddingHorizontal: wp(5), paddingVertical: hp(0) }}>
            <Checkbox selected={anonymous===true} onChangeValue={(val) => { setAnonymous(val) }} />
            <CustomText style={{ marginLeft: wp(5), marginTop: 2 }} font='medium' size={20} color={'#000'}>Anonymous report?</CustomText>
          </View>

          {/* Is Urgent Checkbox  */}
          <View style={{ flexDirection: 'row', alignItems: 'center', paddingHorizontal: wp(5), paddingVertical: hp(0) }}>
            <Checkbox selected={isUrgent===true} onChangeValue={(val) => { setIsUrgent(val) }} />
            <CustomText style={{ marginLeft: wp(5), marginTop: 2 }} font='medium' size={20} color={'#000'}>Mark as Urgent?</CustomText>
          </View>

          {OrgFilter !=null && OrgFilter.length > 1 && <View style={[{width: '100%', flex: 1, marginBottom:'20px', marginTop: '20px'}, screenWidth >= 800 ? {flexDirection: 'row', justifyContent: 'normal'} : {}]}>
              <View style={screenWidth >= 800 ? {marginLeft: '8px', width: '40%'} : {marginLeft: 1, marginBottom: 5, width: '100%'}}>
              <DropDownPicker
                  zIndex={-1}
                  multiple={false}
                  min={0}
                  open={OrgFilterOpen}
                  value={OrgFilterValue}
                  items={OrgFilter}
                  setOpen={setOrgFilterOpen}
                  setValue={setOrgFilterValue}
                  setItems={setOrgFilter}
                  dropDownDirection="TOP"
                  placeholder="Filter by Organization"
                  maxHeight={158}
                  bottomOffset={200}
              />
              </View>

            </View>}

          {/* KPI Chips */}
          <CustomText style={styles.heading} font='medium' size={20} color={'#000'}>Hazard Classifications</CustomText>
          <ChipSection styles={Platform.OS === 'web' && screenWidth >= 800 ? Web_Chip_Styles : Chip_Styles}  
            doSynchronize={false} 
            canAdd={true} 
            canDelete={true}
            sortChips={true} 
            validValues={validKPIs}
            Chips={KPIS}
            setChips={setKPIS}>
            </ChipSection>

          {/* Location Chips */}
          <CustomText style={styles.heading} font='medium' size={20} color={'#000'}>Location</CustomText>
          <ChipSection styles={Platform.OS === 'web' && screenWidth >= 800 ? Web_Chip_Styles : Chip_Styles}  
            doSynchronize={false} 
            canAdd={true} 
            canDelete={true}
            sortChips={true} 
            validValues={validLocations}
            Chips={Locations}
            setChips={setLocations}>
            </ChipSection>

          {/* Submit button  */}
          <TouchableOpacity style={Platform.OS === 'web' && screenWidth >= 800 ? [styles.submitButton, {width: 300 }] : styles.submitButton} onPress={() => postNewTableEntry(true)}>
            <CustomText style={styles.buttonText} font='medium' size={32} color={'#000'}>Submit {isSubmitting && <ActivityIndicator size="small"/>}</CustomText>
          </TouchableOpacity>

        </View>
        </TouchableWithoutFeedback>
      </ScrollView>
      <Footer  admin={admin}/>
      <KeyboardAccessoryNavigation androidAdjustResize avoidKeyboard />
     </View>
    )
}

const styles = StyleSheet.create({
  orangeContainer: {
    //flex: 1,
    backgroundColor: 'rgba(255, 196, 81, 0.3)',
    //alignItems: 'center',
    borderRadius: 12,
    marginTop: hp(4.5),
    height: hp(30),
    width: '100%'
  },
  textInput: {
    width: '100%', 
    textAlign: 'left', 
    textAlignVertical: 'top',
    fontFamily: fonts.POPPINS.medium, 
    fontSize: 24,
    flex: 1, 
    paddingLeft: wp(2),
    paddingTop: hp(2.4)
  },
  placeholder: {
    position: 'absolute', 
    top: 5, 
    alignSelf: 'center',
    color: 'rgba(0,0,0,0.35)',
    fontSize: 24,
  },
  buttonDelete: {
    cursor: 'pointer',
    backgroundColor: '#FF1111',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 12,
    height: hp(6.8),
    width: '50%',
    alignSelf: 'center',
    marginTop: hp(2.5),
  },
  buttonAdd: {
    cursor: 'pointer',
    backgroundColor: '#11FF11',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 12,
    height: hp(6.8),
    width: '50%',
    alignSelf: 'center',
    marginTop: hp(2.5),
  },
  submitButton: {
    cursor: 'pointer', 
    justifyContent: 'center', 
    alignItems: 'center', 
    alignSelf: 'center', 
    backgroundColor: '#FFC451', 
    borderRadius: 12, 
    marginVertical: hp(2),
    width: wp(80),
    height: 70, 
  },
  imageUploadButton: {
    height: 70, 
    cursor: 'pointer', 
    justifyContent: 'center', 
    alignItems: 'center', 
    backgroundColor: '#FFC451', 
    borderRadius: 12, 
    marginVertical: hp(1)
  },
  heading: {
    marginTop: hp(3 * scale),
  },
  Chip:{
    marginHorizontal: hp(0),
    backgroundColor: 'rgba(255,196,81,0.3)',
    justifyContent: 'center',
    flexDirection: 'row',
    flex: 1,
    borderRadius: 100,
    borderColor: 'rgb(0,0,0)',
    borderWidth: 1,
  },
  ChipSelected:{
    marginHorizontal: hp(0),
    backgroundColor: '#FFC451',
    justifyContent: 'center',
    flexDirection: 'row',
    flex: 1,
    borderRadius: 100,
    borderColor: 'rgb(0,0,0)',
    borderWidth: 1,
  },
  ChipAdd:{
    marginHorizontal: hp(0),
    borderRadius: 100,
    backgroundColor: '#CCCCCC',
    justifyContent: 'center',
    flex: 1,
    borderColor: 'rgb(0,0,0)',
    borderWidth: 1,
    minHeight: hp(3),
  },
  ChipText:{
    marginHorizontal: hp(2),
  },
  ChipDeleteText:{
    marginRight: hp(1),
    borderRadius: 100,
  },
  container: {
    height: "100%",
    position: "absolute",
    zIndex: -1,
  },
  baseContainer: {
    backgroundColor: "white",
    flex: 1,
  },
  tallcontainer: {
    backgroundColor: "white",
    flex: 1,
    height: hp(30)
  },
  buttonText: {
    marginVertical: hp(1.8),
    textAlign: "center"

  },
  error: {
    color: "red",
    fontWeight: "bold",
    textAlign: "center"
  }
})

export default SubmitReport;